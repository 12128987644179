import { useState, useRef, useCallback, useEffect } from "react";
import Webcam from "react-webcam";
import cv, {CV_8U,CV_8UC1} from "@techstark/opencv-js";

// Chart JS
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Constraints
const videoConstraints: any = {
  width: 200,
  height: 200,
  sampleSize: 10,
  facingMode: "environment",
  zoom: 4,
};

function InitialConfiguration({
  onConfigurationFinished,
}: {
  onConfigurationFinished: (value: number[]) => void;
}) {
  const webcamRef: any = useRef(null);
  const [snapshots, setSnapshots] = useState<any[]>([]);
  const [finalResults, setFinalResults] = useState<number[] | undefined>(
    []
  );
  let [histdata, setHistdata] = useState([]);
  let [histdata1, setHistdata1] = useState([]);
  let [torchonoff, setTorch] = useState(true);

  const handleTakePicture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setSnapshots([imageSrc]);
    setTimeout(function(){handleAnalyze()},300);
  }, [webcamRef, snapshots]);

  const onloadWebCam = () => {
    console.log("WEBCAM IS LOADED");
  };

  const handleAnalyze = () => {
    // TODO: calculate histograms and send results through onConfigurationFinished method
    const imageOne = cv.imread("0");

    // Imagen uno
    cv.cvtColor(imageOne, imageOne, cv.COLOR_RGBA2RGB, 0); 
    cv.cvtColor(imageOne, imageOne, cv.COLOR_RGB2HSV, 0); 

    // Se supone que la imagen es de 200*200
    let centro = new cv.Point(100,100);
    let mask = cv.Mat.zeros(200,200,CV_8U);
    cv.circle(mask, centro, 50, [1,1,1,0],cv.FILLED);

    let hist = new cv.Mat();
    let hist1 = new cv.Mat();
    
    // Initialise a MatVector
    let matVec = new cv.MatVector();
    // Push a Mat back into MatVector
    matVec.push_back(imageOne);

    try {
      cv.calcHist(matVec, [0], mask, hist, [180], [0, 179]);
    } catch (error) {
      console.error(error);
      throw new Error("ERROR ERROR");
    }
    console.log(hist.data);

    try {
      cv.calcHist(matVec, [2], mask, hist1, [255], [0, 255]);
    } catch (error) {
      console.error(error);
      throw new Error("ERROR ERROR");
    }
    console.log(hist1.data);

    cv.transpose(hist,hist);
    setHistdata(hist.data);

    cv.transpose(hist1,hist1);
    setHistdata1(hist1.data);

    // let unos = cv.countNonZero(array);

    let minVal = new  cv.Mat();
    let resmaxloc: any = cv.minMaxLoc(hist,minVal);
    let max = resmaxloc.maxLoc.x;  

    let res = [max-6,max+2,25000];
    
    // console.log(max);
    setFinalResults(res);
  };

  const handleFinish = () => {
    if (finalResults) {
      onConfigurationFinished(finalResults);
    }
  };

  const turnTorch = () => {
    setTorch((torchonoff) => !torchonoff);
    // Agrego para prender el flash cuando saca las imagenes de calibracion
    let track = webcamRef?.current?.stream.getVideoTracks()[0];
    const capa = track.getCapabilities();
    const settings = track.getSettings();
    // console.log(capa);
    // console.log(settings);
    if (!("zoom" in settings)) {
      return Promise.reject("Zoom is not supported by " + track.label);
    }
    track.applyConstraints({
      advanced: [{ 
        torch: torchonoff,
        // focusMode: "continuous",
        // whitebalanceMode: "manual"
      }],
    });
    console.log(torchonoff);
  };

  // Configuracion Chart JS
  const options = {
    responsive: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  };

  const labels = [];

  for (var i = 700; i <= 1020; i++) {
    labels.push(i);
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'H',
        data: histdata.slice(0,100), 
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'V',
        data: histdata1.slice(700,1020),
        backgroundColor: 'rgba(99, 255, 132, 0.5)',
      },
    ],
  };

  return (
    <div>
      <h3>SeptiScan 2024 V0.3</h3>
      <div className="camera">
        <Webcam
          ref={webcamRef}
          onLoad={onloadWebCam}
          imageSmoothing={false}
          screenshotFormat="image/jpeg"
          style={{ opacity: 1, width: 200, height: 200 }}
          videoConstraints={videoConstraints}
          audio={false}
          screenshotQuality={1}
        />
        <div className="circle"></div>
        {snapshots.map((image) => (
          <img
            id= "0"
            key= "0"
            className="inputImage"
            alt="input"
            src={image}
          />
        ))}
      </div>

      <div style={{ alignContent: "center", width: "100%", marginTop: 20 }}>
        {!torchonoff && <button onClick={handleTakePicture}>TOMAR IMAGEN</button>}
        <br />
        <br />
        {finalResults && <button onClick={handleFinish}>FINALIZAR</button>}
        <br />
        <br />
        <button onClick={turnTorch}>LED ON/OFF</button>
        <br />
        <br />
        <span> Corte: {finalResults?.at(0)} </span>
        {/* <br />
        <span> Corte superior: {finalResults?.at(1)} </span>
        <br />
        <span> Area: {finalResults?.at(2)} </span> */}
        <div> <Line options={options} data={data} /></div>
      </div>
    </div>
  );
}

export default InitialConfiguration;
