import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import cv from "@techstark/opencv-js";

import { processImage } from "../utils/imageProcessing";
import { Line } from 'react-chartjs-2';


// Contrains
const videoConstraints: any = {
  width: 200,
  height: 200,
  sampleSize: 10,
  facingMode: "environment",
  zoom: 4,
};

function ImageProccesor({ cutNumber }: { cutNumber?: number[] }) {
  const webcamRef: any = React.useRef(null);
  const imgRef: any = React.useRef(null);
  const inRef: any = React.useRef(null);
  const [detectedPixels, setDetectedPixels] = useState(0);
  const [detectedPixelsPRES, setDetectedPixelsPRES] = useState(0);
  const alfa: number = 90;
  const beta: number = 20;
  const [cantidad_de_corte, setCantidad_de_corte] = useState(-1);
  const [tiempo_init, setTiempo_init] = useState(-1);
  const [tiempo_end, setTiempo_end] = useState(-1);
  let DEBUG = true;
  let [resultado, setResultado] = useState(0);
  let [torchonoff, setTorch] = useState(false);
  let [counter, setCounter] = useState(10);
  const [datatrc, setDatatrc] = useState<number[]>([]); // Establecer el tipo explícitamente como un array de números
  let c=0;
  const labels: number[] = [];
  const largoarray=500;
  let [chart,setChart] = useState(false);
  let [percentage,setPercentage] = useState(0);
  const [tiempo, setTiempo] = useState(new Date());

  for (var i=0;i<largoarray;i++){
    labels.push(i);
  };

  function actDataTRC(pixels: number) {
    if (datatrc.length > largoarray) {
      setDatatrc([]);
    } else {
      setDatatrc(prevDatatrc => [...prevDatatrc, pixels]);
    }
  };

  useEffect(() => {
    const intervalo = setInterval(() => {
      // Actualiza el tiempo cada segundo
      setTiempo(new Date());
    }, 10);

    setPercentage(detectedPixels*100/(detectedPixels+detectedPixelsPRES));
    if (!chart) {
      actDataTRC(percentage);
      setChart(true);
    } else {setChart(false)}
    if (percentage < beta) {
      if (counter > 0){
        setTimeout(function(){setCounter(counter-1)},1000);
      }
      //Aca estoy apretado
      setCantidad_de_corte(percentage);
      setTiempo_init(-1);
    } else if (percentage >= beta && cantidad_de_corte > -1) {
      //Aca el dedo se esta llenando de sangre
      c=c+1;
      if (tiempo_init == -1) {
        //Aca entra la primera vez que el detectedPixels < alfa
        c = 0;
        setTiempo_init(Date.now());      
      }
      setCantidad_de_corte(-1);
    } else if (
      tiempo_init > -1 &&
      percentage > alfa 
    ) {
      setTiempo_end(Date.now());
    } else if (percentage < beta && cantidad_de_corte > -1) {
      //NO tengo que entro aca NUNCA 
    }
    if (tiempo_init > -1 && tiempo_end > -1) {
      //Aca calculo el tiempo y reinicio los contadores podria entrar en la linea luego de console.log("tiempo end =" + tiempo_end);
      if (DEBUG)
        console.log(
          "TIEMPO TRANSCURRIDO ES = " +
            (tiempo_end - tiempo_init) / 1000 +
            " segundos"
        );
      setTiempo_init(-1);
      setTiempo_end(-1);
      setCantidad_de_corte(-1);
      setResultado((resultado = (tiempo_end - tiempo_init) / 1000));
      setCounter(10);
      c = 0;
    }
    if (DEBUG) console.log("END- ########"); 
    return () => clearInterval(intervalo); 
  // }, [detectedPixels, tiempo_end, tiempo_init]);
    }, [tiempo]);

  useEffect(() => {
    const process = async () => {
      const imageSrc = webcamRef?.current?.getScreenshot();
      if (webcamRef?.current?.stream !== undefined && imgRef?.current) {
        let track = webcamRef?.current?.stream.getVideoTracks()[0];
        const capa = track.getCapabilities();
        const settings = track.getSettings();
        if (!("zoom" in settings)) {
          return Promise.reject("Zoom is not supported by " + track.label);
        }
        track.applyConstraints({
          advanced: [{ torch: torchonoff }],
        });

        if (!imageSrc) return;
        return new Promise((resolve: any, reject: any) => {
          imgRef.current.src = imageSrc;
          imgRef.current.onload = () => {
            console.log("La imagen de la webcam se ha cargado correctamente");
            try {
              const img = cv.imread(imgRef.current);
              const proccessedData = processImage(img,cutNumber);
              if (torchonoff) {
                setDetectedPixels(proccessedData.detectedPixels);
                setDetectedPixelsPRES(proccessedData.detectedPixels2);
                cv.imshow(inRef.current, proccessedData.image);
              } else {
                cv.imshow(inRef.current, proccessedData.croppedImage);
              }
              img.delete();
              resolve();
            } catch (error: unknown) {
              if (error instanceof Error) {
                console.error(error);
                resolve();
              } else {
                console.error("Error desconocido:", error);
                reject();
              }
            }
          };
        });
      }
    };

    let handle: any;
    const nextTick = () => {
      handle = requestAnimationFrame(async () => {
        await process();
        nextTick();
      });
    };
    nextTick();

    return () => {
      cancelAnimationFrame(handle);
    };
  });

  const onloadWebCam = () => {
    debugger;
  };

  // Torch on off
  const turnTorch = () => {
    setTorch((torchonoff) => !torchonoff);
  };

  // Configuracion Chart JS
  const options = {
    animation: {
      duration: 0, // establece la duración de la animación a 0 para desactivarla
    },
    scales: {
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: '[%]',
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Curva TRC',
        data: datatrc, 
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  return (
    <div className="App">
      <h3>SeptiScan 2024 V0.3</h3>
      <div className="camera">
        <Webcam
          ref={webcamRef}
          className="webcam"
          onLoad={onloadWebCam}
          imageSmoothing={false}
          screenshotFormat="image/jpeg"
          style={{ position: "absolute", opacity: 0, width: 200, height: 200 }}
          videoConstraints={videoConstraints}
          audio={false}
          screenshotQuality={1}
        />
        <img
          className="inputImage"
          alt="input"
          ref={imgRef}
          style={{ opacity: 0, position: "absolute", top: 0, left: 0 }}
        />
        <canvas
          ref={inRef}
          style={{
            width: 300,
            height: 300,
          }}
        />
      </div>
      <div style={{ alignContent: "center", width: "100%", marginTop: 20 }}>
        <span> Pixeles detectados: {detectedPixels}</span>
        <br />
        <span> Pixeles presionados detectados: {detectedPixelsPRES}</span>
        <br />
        <br />
        <span> El TRC es de: {resultado} seg. </span>
        <br />
        <br />
        <button onClick={turnTorch}>LED ON/OFF</button>
        <br />
        <br />
        <span> Contador: {counter} </span>
        <div> <Line options={options} data={data} /></div>
      </div>
    </div>
  );
}

export default ImageProccesor;
